import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import { Collapse, List, Typography } from '@mui/material'
import { PropsWithChildren, ReactNode, useState } from 'react'
import { SideMenuItemButton } from './SideMenuItemButton'
import { Icon } from './Icon'

type SideMenuItemCollapseProps = {
  label: string
  icon?: ReactNode
}

export const SideMenuItemCollapse = ({
  label,
  children,
  icon,
}: PropsWithChildren<SideMenuItemCollapseProps>) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <SideMenuItemButton
        onClick={() => {
          setOpen((prevState) => !prevState)
        }}
        sx={{
          backgroundColor: ({ palette }) => palette.primary.main,
          '&:hover': {
            backgroundColor: ({ palette }) => palette.primary.dark,
          },
        }}
      >
        {icon}

        <Typography
          variant="button"
          sx={{ mr: 'auto', ...(icon && { ml: 2 }) }}
        >
          {label}
        </Typography>

        <Icon path={open ? mdiChevronUp : mdiChevronDown} size={1} />
      </SideMenuItemButton>

      <Collapse in={open}>
        <List disablePadding>{children}</List>
      </Collapse>
    </>
  )
}
