import { AppBar, Button, Toolbar } from '@mui/material'
import { signOut } from 'next-auth/react'
import Image from 'next/image'
import { useRouter } from 'next/router'

export const Header = () => {
  const router = useRouter()

  if (router.pathname === '/') {
    return null
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <Image src="/logo.png" width={150} height={28} alt="Logo" />

        <Button
          variant="text"
          sx={{ ml: 'auto', color: 'white' }}
          onClick={() => {
            signOut()
          }}
        >
          Sair
        </Button>
      </Toolbar>
    </AppBar>
  )
}
