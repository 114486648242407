import MdiIcon from '@mdi/react'
import { type SxProps, styled } from '@mui/material/styles'
import { Theme } from '@mui/system'

type IconProps = {
  path: string
  size?: number
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
  sx?: SxProps<Theme>
  className?: string
}

const BaseIcon = styled(MdiIcon)({})

export const Icon = ({ color = 'inherit', size, sx, ...props }: IconProps) => (
  <BaseIcon
    {...props}
    size={size}
    sx={{
      color: ({ palette }) =>
        color === 'inherit' ? 'inherit' : palette[color].main,
      ...sx,
    }}
  />
)
