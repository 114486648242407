import { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'
import { Container } from '@mui/material'
import { useRouter } from 'next/router'
import { SideMenu } from '../molecules/SideMenu'

export const Layout = ({ children }: PropsWithChildren) => {
  const router = useRouter()

  return (
    <Box component="main" display="flex" flex={1}>
      {router.pathname.includes('/dashboard') && <SideMenu />}

      <Container
        maxWidth="xl"
        sx={{
          py: 4,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Container>
    </Box>
  )
}
