import { ListItemButton, ListItemButtonProps } from '@mui/material'

type SideMenuItemButtonProps = { href?: string } & ListItemButtonProps

export const SideMenuItemButton = ({
  children,
  sx,
  ...rest
}: SideMenuItemButtonProps) => {
  return (
    <ListItemButton
      sx={{
        ...sx,
        pr: 4,
        py: 1.5,
        borderTopRightRadius: 50,
        borderBottomRightRadius: 50,
      }}
      {...rest}
    >
      {children}
    </ListItemButton>
  )
}
