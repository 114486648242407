import { List } from '@mui/material'
import { Icon } from '@mdi/react'
import {
  mdiAccount,
  mdiCashMultiple,
  mdiFileSign,
  mdiHomeOutline,
  mdiOfficeBuildingCogOutline,
  mdiPackageVariant,
} from '@mdi/js'
import { SideMenuItemCollapse } from '@/atoms/SideMenuItemCollapse'
import { SideMenuItem } from '@/atoms/SideMenuItem'

export const SideMenu = () => {
  return (
    <List sx={{ color: 'white', width: 240 }} disablePadding>
      <SideMenuItem
        variant="category"
        label="Início"
        href="/dashboard"
        icon={<Icon path={mdiHomeOutline} size={1} />}
      />

      <SideMenuItemCollapse
        label="Clientes"
        icon={<Icon path={mdiOfficeBuildingCogOutline} size={1} />}
      >
        <SideMenuItem
          variant="item"
          label="Lista de clientes"
          href="/dashboard/clients"
        />

        <SideMenuItem
          variant="item"
          label="Adicionar cliente"
          href="/dashboard/clients/create"
        />
      </SideMenuItemCollapse>

      <SideMenuItemCollapse
        label="Produtos"
        icon={<Icon path={mdiPackageVariant} size={1} />}
      >
        <SideMenuItem
          variant="item"
          label="Lista de produtos"
          href="/dashboard/products"
        />

        <SideMenuItem
          variant="item"
          label="Adicionar produto"
          href="/dashboard/products/create"
        />
      </SideMenuItemCollapse>

      <SideMenuItemCollapse
        label="Contratos"
        icon={<Icon path={mdiFileSign} size={1} />}
      >
        <SideMenuItem
          variant="item"
          label="Lista de Contratos"
          href="/dashboard/contracts"
        />

        <SideMenuItem
          variant="item"
          label="Adicionar Contrato"
          href="/dashboard/contracts/create"
        />
      </SideMenuItemCollapse>

      <SideMenuItemCollapse
        label="Faturamento"
        icon={<Icon path={mdiCashMultiple} size={1} />}
      >
        <SideMenuItem
          variant="item"
          label="Lista de faturas"
          href="/dashboard/financing"
        />
      </SideMenuItemCollapse>

      <SideMenuItem
        variant="category"
        label="Usuários"
        href="/dashboard/users"
        icon={<Icon path={mdiAccount} size={1} />}
      />
    </List>
  )
}
