import { Typography } from '@mui/material'
import Link from 'next/link'
import { ReactNode } from 'react'
import type { Route } from 'nextjs-routes'
import { SideMenuItemButton } from './SideMenuItemButton'

type SideMenuItemProps = {
  href: Route['pathname']
  label: string
  variant: 'category' | 'item'
  icon?: ReactNode
}

export const SideMenuItem = ({
  href,
  label,
  variant,
  icon,
}: SideMenuItemProps) => {
  return (
    <SideMenuItemButton
      sx={{
        ...(variant === 'category' && {
          backgroundColor: ({ palette }) => palette.primary.main,
          '&:hover': {
            backgroundColor: ({ palette }) => palette.primary.dark,
          },
        }),
        ...(variant === 'item' && {
          color: ({ palette }) => palette.secondary.main,
        }),
      }}
      LinkComponent={Link}
      href={href}
    >
      {icon}

      <Typography variant="button" sx={{ ...(icon && { ml: 2 }) }}>
        {label}
      </Typography>
    </SideMenuItemButton>
  )
}
