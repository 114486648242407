import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { Roboto } from 'next/font/google'

export const roboto = Roboto({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
})

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#ff5c35',
        contrastText: '#FFF',
      },
      secondary: {
        main: '#4A36BD',
        contrastText: '#FFF',
      },
    },
    typography: {
      fontFamily: roboto.style.fontFamily,
    },
  }),
)
